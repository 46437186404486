<template>
	<div class="contract-items-page">
		<Header :pageTitle="contractName" />
		<SubHeader :currentPage="'on_site.menu.calloff_creation'.trans()"/>

		<div class="contract-items-container" v-show="contractStatus == 0">
			<FilterSearch
				:contract-items="true"
				filter-action="contractItems/fetchContractItemsOnLoad"
				:filter-parameter="getFiltersParameter"
				filter-route="filters/fetchContractItemsFilterItems"
				page-name="contractItems"
				parameter-name="contract_number"
				:parameter-value="contractNumber"
				:query-parameter="getQueryParameter"
				searchbar-placeholder="on_site.search_filter.material.placeholder"
			/>
			<template v-for="(block, i) in paginationBlocks">
				<Loader :prevent-loading-screen="i < current_page">
					<div class="contract-items">
						<p class="contract-items-title" v-if="hasResults && i === 0">{{'on_site.contract-items.title'.trans()}}</p>
						<div class="filter-parent-container" v-if="hasResults">
							<ContractItemCard
								v-for="item in block"
								:item="item"
								:key="item.contract_item_id"
							/>
						</div>
						<div class="contract-items-title empty" v-else>
							<span class="empty-title">{{'on_site.contract-items.empty'.trans()}}</span>
								<div class="empty-screen-icon-container">
									<img class="contract-items" src="@/assets/icons/amount-empty.svg" alt="empty_contract-items"/>
								</div>
							<span class="empty-message">{{'on_site.contract-items.empty_message'.trans()}}</span>
						</div>
					</div>
				</Loader>
			</template>
		</div>

        <div class="contract-closed-container" v-show="contractStatus != 0">
            <div class="title">
                {{ ('on_site.contract-items.contract-closed.title-' + contractStatusText).trans() }}
            </div>
            <div class="close">
                <router-link :to="{name: 'contract-detail', params: {'id': contractId}}">
                    <button @click="handleClick">
                        <img class="bell" src="@/assets/icons/close-x.svg" alt="close"/>
                    </button>
                </router-link>
            </div>
            <div class="top-text" style="clear: both">
                {{ ('on_site.contract-items.contract-closed.top-text-' + contractStatusText).trans() }}
            </div>
            <div class="middle-image">
                <img src="@/assets/img/stop.svg">
            </div>
            <div class="bottom-text">
                {{ ('on_site.contract-items.contract-closed.bottom-text-' + contractStatusText).trans() }}
            </div>
            <div class="button-container">
                <router-link :to="{name: 'contract-detail', params: {'id': contractId}}">
                    <button class="go-back" @click="handleClick">
                        {{ 'on_site.contract-items.contract-closed.button-back'.trans() }}
                    </button>
                </router-link>
            </div>
        </div>

		<Footer/>
	</div>
</template>

<script setup>
    import { ref } from 'vue'
	import { computed, onMounted, onUnmounted } from 'vue'
	import { useStore } from 'vuex'
	import { useRoute } from 'vue-router'

	import Header from '@/components/Common/Header.vue'
	import SubHeader from '@/components/SubHeader/SubHeader.vue'
	import Footer from '@/components/Common/Footer.vue'
	import Loader from '@/components/Common/Loader.vue'
	import ContractItemCard from '@/components/CallOffs/ContractItemCard.vue'
	import FilterSearch from "@/components/Common/FilterSearch.vue";

	const store = useStore()
	const route = useRoute()

	const { contracts: { selectedContract: { contract_name: contractName, contract_number: contractNumber }}} = store.state
	const items = computed(() => store.getters['contractItems/getContractItems'])

    const contractId = ref(store.state.contracts.selectedContract.contract_id)
    const contractStatus = ref(store.state.contracts.selectedContract.contract_status)
    const contractStatusText = ref(store.state.contracts.selectedContract.contract_status_text)

	// Pagination and has results flag
	const hasResults = computed(() => items.value.length > 0)
	const pagination = computed(() => store.getters['contractItems/getContractPagination'])
	const { current_page } = pagination.value

	const paginationBlocks = computed(() => items.value.reduce((resultArray, item, index) => {
		const { total_items } = pagination.value
		const chunkIndex = Math.floor(index/total_items)

		resultArray[chunkIndex] = resultArray[chunkIndex] ? [...resultArray[chunkIndex], item] : [item]

		return resultArray
	}, []))

	// Query parameter from search component that needs to be put into pagination for loading more results
	let paginationQueryParameter = ''
	function getQueryParameter(val) {
		paginationQueryParameter = val
	}

	// Filters parameter from filters popup - used for pagination
	let filtersParameter = ''
	function getFiltersParameter(val) {
		filtersParameter = val
	}

	onMounted(() => {
		window.onscroll = () => {
			const { current_page, has_next_page } = pagination.value
			const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight
			if (bottomOfWindow && has_next_page && !$('.contracts-container').hasClass('hidden')) {
				const length = computed(() => store.getters['getLoadingCalls'])
				if (length.value.length === 0)
					store.dispatch('contractItems/fetchContractItemsPaginated', {
						contract_number: contractNumber,
						page: current_page + 1,
						'filterString': filtersParameter
					})
			}
		}
	})

	onUnmounted(() => {
		store.commit('filters/setSelectedFilters', '')
		store.commit('filters/setSelectedQuery', '')
		window.onscroll = () => {}
	})
</script>

<style lang="sass" scoped>
.contract-items-page
	min-height: calc(100vh - 51px - 48px - 81px)
	padding-bottom: 81px // footer + some extra height
	padding-top: 51px + 48px + 11px // header + subheader + margin from design

	.contract-closed-container
		background: $white
		border-radius: 6px
		box-shadow: 0 3px 3px #00000012
		margin: 10px 15px
		padding: 10px 15px 20px
		position: relative

		.title
			font-family: PoppinsBold
			font-size: 14px
			padding-right: 25px
			text-align: left

		.close
			position: absolute
			right: 5px
			top: 10px

			button
				background: none
				border: none
				cursor: pointer

		.top-text,
		.bottom-text
			font-family: PoppinsRegular
			font-size: 13px
			line-height: 24px
			margin: 10px 0
			text-align: left

		.middle-image
			margin: 30px 0
			padding: 0 15px

			img
				max-width: 100%

		.button-container
			margin-top: 30px

			button
				background: $text-gray
				border: none
				border-radius: 4px
				color: $white
				cursor: pointer
				font-family: PoppinsMedium
				font-size: 16px
				min-width: 220px
				padding: 8px 10px
				text-aling: center

	.contract-items-container
		display: flex
		flex-wrap: wrap
		justify-content: space-evenly
		padding: 0 24px
		position: relative

	.contract-items
		width: 100%

		.filter-parent-container
			display: grid
			gap: 10px
			grid-template-columns: 1fr 1fr

			@media(max-width: 767px)
				grid-template-columns: 1fr

			&.hidden
				display: none

	.contract-items-title
		font-family: PoppinsMedium
		font-size: 12px
		letter-spacing: 0.02px
		line-height: 16px
		color: $text-gray
		text-align: left

		&.empty
			margin-top: 15px
			padding-left: 0
			margin-left: 0
			text-align: center
			font-family: PoppinsMedium
			font-size: 12px
			color: $text-gray

			.empty-screen-icon-container
				margin-bottom: 10px

				img
					height: 200px

			.empty-title
				font-family: PoppinsBold
				font-size: 16px
</style>