<template>
    <div class="ma-snippet"></div>
</template>

<script setup>
    import {useStore} from 'vuex'
    import {useRoute} from 'vue-router'
    import {makeRequest} from '@/utils/makeRequest'
    import {onMounted} from 'vue'
    const store = useStore()
    const route = useRoute()

    async function fetchMarketingAutomationData() {
        try {
            const path = route.path
            const params = {
                'url': path.replace(/^\/+/, ''),
                'targetGroup': 'on-site'
            }
            const response = await makeRequest('get', 'api_marketing_automation_snippet', params)
            renderSnippet(response)
        } catch (err) {
            console.error('Error fetching detail data:', err)
        }
    }

    function getStorageKey(scope) {
        const today = new Date().toISOString().split('T')[0]
        return scope === 'session_with_variables' ? `closedSnippets_${today}` : 'closedSnippets'
    }

    function getClosedSnippets(scope) {
        const storageKey = getStorageKey(scope)
        const storage = scope === 'session_with_variables' ? localStorage : sessionStorage
        return JSON.parse(storage.getItem(storageKey)) || []
    }

    function saveClosedSnippet(scope, snippetId) {
        const storageKey = getStorageKey(scope)
        const storage = scope === 'session_with_variables' ? localStorage : sessionStorage
        let closedSnippets = getClosedSnippets(scope)

        if(!closedSnippets.includes(String(snippetId))) {
            closedSnippets.push(String(snippetId))
            storage.setItem(storageKey, JSON.stringify(closedSnippets))
        }
    }

    function renderSnippet(response) {
        if(!response) return

        let snippetContainer = $('.ma-snippet')
        const { snippet_document_id, scope, html } = response
        const closedSnippets = getClosedSnippets(scope)

        if(closedSnippets.includes(String(snippet_document_id))) return

        const updatedHtml = html.replace('slide-in-type', 'overlay-type') // Temporary fix - only allow overlay-type

        snippetContainer
            .attr('data-snippet-id', snippet_document_id)
            .attr('data-scope', scope)
            .html(updatedHtml)
    }

    function handleSnippetScope(snippet) {
        const snippetId = snippet.attr('data-snippet-id')
        const scope = snippet.attr('data-scope')
        saveClosedSnippet(scope, snippetId)
    }

    function closeSnippet(element) {
        const snippet = element.closest('.ma-snippet')

        if(snippet.length > 0) {
            handleSnippetScope(snippet)
            snippet.remove()
        }
    }

    function cleanClosedSnippets() {
        const today = new Date().toISOString().split('T')[0]

        Object.keys(localStorage).forEach(key => {
            if(key.startsWith('closedSnippets_')) {
                const datePart = key.replace('closedSnippets_', '')

                if(datePart < today) {
                    localStorage.removeItem(key)
                }
            }
        })
    }

    $(document).on('click', '.ma-snippet .target-close', function() {
        closeSnippet($(this))
    })

    onMounted(async() => {
        cleanClosedSnippets()
        await fetchMarketingAutomationData()
    })
</script>

<style lang="sass">
.snippet-container
    &.overlay-type
        .overlay
            background: rgba($black, 0.6)
            bottom: 0
            display: flex
            height: 100%
            left: 0
            overflow-y: auto
            position: fixed
            right: 0
            top: 0
            width: 100%
            z-index: 10000

            .title
                line-height: 36px
                margin-bottom: 20px
                text-transform: uppercase

                @media screen and (max-width: 920px)
                    font-size: 16px
                    line-height: 28px

            .form-group
                padding-left: 30px
                position: relative

                &:last-child
                    margin-bottom: 40px

                .input-radio
                    left: 0
                    position: absolute

                    @media screen and (max-width: 920px)
                        top: 3px

                label
                    display: block
                    font-family: PoppinsSemiBold
                    font-size: 18px
                    margin: 0

                    @media screen and (max-width: 920px)
                        font-size: 14px

            .container
                width: 100%

                > .content
                    background-color: $white
                    box-shadow: 0 3px 6px rgba(47, 68, 84, 0.3)
                    font-size: 13px
                    margin: 120px auto
                    max-height: calc(100vh - 190px)
                    max-width: 100%
                    overflow-y: auto
                    padding: 40px 20px 20px
                    position: relative
                    width: 850px

                    @media screen and (max-width: 920px)
                        margin: 120px 25px
                        width: calc(100% - 50px)

                    .target-close
                        cursor: pointer
                        font-family: PoppinsSemiBold
                        font-size: 12px
                        line-height: 28px
                        position: absolute
                        right: 20px
                        top: 10px

                    .content-centered
                        margin: auto
                        max-width: 100%
                        width: 600px

                        .title
                            font-family: PoppinsSemiBold

                    .options-container
                        .option-text
                            display: flex
                            font-family: Poppins
                            font-size: 18px
                            margin-bottom: 10px

                        .selection-text
                            font-family: Poppins
                            font-size: 18px

                    .option-targets-container
                        height: 0
                        opacity: 0
                        transition: 0.6s

                        &.fade-in
                            opacity: 1

                        .option-targets
                            height: 0
                            opacity: 0
                            pointer-events: none
                            transition: 0.6s

                            &.auto-height
                                height: auto

                            &.visible
                                height: auto
                                opacity: 1
                                pointer-events: all

                            .options
                                display: flex
                                flex-direction: row
                                margin: 20px -10px 0
                                width: calc(100% + 20px)

                                @media screen and (max-width: 920px)
                                    flex-wrap: wrap

                                .option
                                    margin: 0 10px 10px
                                    width: 100%

                                    a
                                        background-size: cover !important
                                        display: flex
                                        height: 250px
                                        position: relative
                                        width: 100%

                                        &:after
                                            background: linear-gradient(to bottom,rgba(0,0,0,.25) 0,transparent 25%)
                                            content: ''
                                            height: 100%
                                            left: 0
                                            position: absolute
                                            top: 0
                                            width: 100%
                                            z-index: 10

                                        .title
                                            font-family: PoppinsBold
                                            font-size: 18px
                                            left: 15px
                                            line-height: 36px
                                            position: absolute
                                            top: 5px
                                            z-index: 11

                    .web-multi-teaser-marketing-container
                        padding: 0

    &.slide-in-type
        &.slide-in, &.preview
            .overlay
                .content
                    left: 0
                    pointer-events: all

        &.direction
            &-right
                .overlay
                .content
                    left: auto
                    right: -800px

                &.slide-in, &.preview
                    .overlay
                        .content
                            right: 0

        .overlay
            bottom: 0
            color: $white
            display: flex
            height: 100%
            left: 0
            overflow-y: scroll
            position: fixed
            right: 0
            top: 0
            width: 100%
            z-index: 10000

            .content
                box-shadow: 0 3px 6px rgba(47, 68, 84, 0.3)
                height: 100%
                left: -800px
                max-width: 100%
                overflow-y: scroll
                padding: 115px 70px
                position: fixed
                transition: 1s
                width: 700px

                &.background
                    &-white
                        background: $white

                > div:last-child
                    margin-bottom: 100px

                .target-close
                    font-family: PoppinsSemiBold
                    font-size: 12px
                    line-height: 28px
                    position: absolute
                    right: 20px
                    top: 36px

                    .icon-close
                        float: right
                        margin: 0 0 0 10px

                        .a
                            fill: $white

                    &:hover
                        cursor: pointer

                .text
                    padding: 50px 0

                    h2
                        font-family: PoppinsBold
                        font-size: 40px
                        line-height: 52px
                        margin-bottom: 30px
                        text-transform: uppercase

                    p
                        font-family: PoppinsSemiBold
                        font-size: 28px
                        line-height: 42px

                .button-container
                    margin-bottom: 115px
                    text-align: center

                    .button
                        display: inline-block
                        font-family: PoppinsBold
                        font-size: 24px
                        padding: 20px 80px
                        text-align: center
                        text-decoration: none !important
                        text-transform: uppercase

                .web-text-container
                    .container
                        padding: 0

                        .web-text
                            padding: 0

                .web-image-container
                    .container
                        padding: 0

                        .web-image
                            padding: 0

    &.service-rating-type
        &.editmode
            .container
                margin: 0px !important

                .content
                    position: absolute
                    left: 50% !important
                    top: 50% !important

                    .pimcore_tag_block
                        display: flex

                        .pimcore_block_entry
                            margin-right: 5px

                    .target-close
                        display: none

                    .service-rating-popup
                        box-shadow: none !important

                        .rating-elements-container
                            padding: 0px !important
                            margin: 0px !important

                            input[type="radio"]
                                position: absolute
                                visibility: hidden
                                opacity: 0

                                + label
                                    display: inline-block !important
                                    width: 100% !important

                            .pimcore_tag_image
                                min-height: 150px

                            .pimcore_tag_image_alt
                                position: absolute
                                visibility: hidden
                                opacity: 0

                            .rating-element-image
                                &.editmode
                                    div
                                        margin: auto

                            .rating-element-text
                                margin-top: 20px
                                font-family: PoppinsMedium
                                font-size: 18px
                                letter-spacing: 0px
                                text-align: center

                            .rating-element-textarea-checkbox
                                margin-right: 20px

                                div
                                    display: flex
                                    margin-top: 16px

                                    input
                                        margin-right: 5px

                        .service-rating-popup-submit
                            display: none

        .overlay
            position: fixed
            display: flex
            top: 0px
            bottom: 0px
            left: 0px
            right: 0px
            background: rgba(255,255,255,0.93)
            z-index: 10000

            .container
                position: relative

                .content
                    position: absolute
                    top: 46%
                    left: 50%
                    transform: translate(-50%, -50%)

                    .target-close
                        text-align: end
                        margin-bottom: 0px
                        margin-right: 10px
                        cursor: pointer

                    .service-rating-popup
                        width: max-content
                        padding: 30px
                        background-color: $white
                        box-shadow: 0px 3px 6px rgba(0,0,0,0.16)

                        &.is-requesting
                            pointer-events: none

                            .service-rating-popup-title,
                            .rating-elements,
                            .submit-button-container
                                opacity: 0.2

                            .loader
                                display: block

                        .service-rating-popup-title
                            margin-top: 45px
                            margin-bottom: 0px
                            font-family: PoppinsLight
                            font-size: 25px
                            letter-spacing: 0px
                            text-align: center

                        .rating-elements-container
                            width: max-content
                            margin-left: 234px
                            margin-right: 198px

                            .rating-elements
                                position: relative
                                display: flex
                                margin-top: 45px
                                margin-bottom: 30px

                                input[type="radio"]
                                    position: absolute
                                    visibility: hidden
                                    opacity: 0

                                    + label
                                        display: flex
                                        align-items: end

                                .rating-element
                                    margin-right: 36px
                                    cursor: pointer

                                    .rating-element-text
                                        margin-top: 20px
                                        font-family: PoppinsMedium
                                        font-size: 18px
                                        letter-spacing: 0px
                                        text-align: center

                            .service-rating-textarea-container
                                margin-bottom: 30px

                                .service-rating-textarea-header
                                    display: flex
                                    justify-content: space-between
                                    padding-left: 5px
                                    padding-right: 5px
                                    font-family: PoppinsMedium
                                    font-size: 18px
                                    letter-spacing: 0px

                                    .textarea-length-container
                                        font-size: 14px

                                textarea
                                    width: 100%
                                    min-height: 80px
                                    padding-left: 5px
                                    padding-right: 5px
                                    resize: none
                                    font-family: Poppins
                                    border-radius: 4px
                                    border-color: rgba(96, 112, 135, 0.5)

                        .submit-button-container
                            text-align: end

                            &.submit-hidden
                                visibility: hidden

                            .service-rating-popup-submit
                                padding: 14px 33px
                                margin-bottom: 10px
                                margin-right: -7px
                                font-family: PoppinsSemiBold
                                font-size: 17px
                                letter-spacing: -0.17px
                                text-transform: uppercase
                                color: $white
                                border: none
                                border-radius: 4px

                        .loader
                            display: none
                            position: absolute
                            top: 50%
                            left: 50%
                            transform: translate(-50%, -50%)
</style>