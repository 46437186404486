<template>
	<div class="main-header">
		<span class="hamburger" @click="triggerMenu" :class="{ 'is-Active': isMenuOpen }">
			<span class="line"></span>
			<span class="line"></span>
			<span class="line"></span>
		</span>
		<span class="title">{{ title }}</span>
	</div>
	<Menu class="menu-container" :class="{ 'closed': !isMenuOpen }"/>
	<FlashMessage/>
    <MarketingAutomation/>
</template>

<script setup>
	import { ref } from 'vue'
	import Menu from '@/components/Menu.vue'
	import FlashMessage from '@/components/Common/FlashMessage.vue'
    import MarketingAutomation from "@/components/Common/MarketingAutomation";

	const props = defineProps({
		pageTitle: String
	})

	const isMenuOpen = ref(false)
	const title = ref(props.pageTitle)

	$('html').removeClass('no-scroll')
	$('html').removeClass('no-pull-to-refresh')

	const triggerMenu = () => {
		const newValue = !isMenuOpen.value

		// Toggle value & title
		isMenuOpen.value = newValue
		title.value = newValue ? 'on_site.menu.menu-title'.trans() : props.pageTitle

		// Toggle scroll on body
		if (newValue) $('html').addClass('no-scroll')
		else $('html').removeClass('no-scroll')
	}
</script>

<style lang="sass" scoped>
.main-header
	display: flex
	align-items: center
	background-color: #063973
	text-align: left
	box-shadow: 0 3px 6px #01000029

	z-index: 9999
	top: 0
	position: fixed

	height: 51px
	width: 100%
	min-width: 250px
	max-width: 900px

	.hamburger
		cursor: pointer !important
		border: 0 !important
		outline: 0 !important
		background: transparent !important

		padding-left: 24px

		outline: none
		-webkit-tap-highlight-color: rgba(0,0,0,0)

	.line
		width: 18px
		height: 3px

		border-radius: 8px

		background-color: $white
		display: block
		margin: 3px auto

		transition: all 0.3s ease-in-out

	&.is-Active
		.line
			&:nth-child(2)
				opacity: 0

			&:nth-child(1)
				transform: translateY(6px) rotate(45deg)

			&:nth-child(3)
				transform: translateY(-6px) rotate(-45deg)

	.title
		color: $white
		font-family: "MontserratSemiBold"
		margin-left: 18px
		font-size: 12px
		line-height: 15px
</style>